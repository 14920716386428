<template>
  <step-wrapper title="Выводы" class="consolidated-report-step6">
    <v-form :readonly="readonly" v-model="valid">
      <div class="mt-5">
        <v-textarea
          v-if="dataSource"
          :value="dataSource.Conclusion"
          @input="dataSource.Conclusion = $event"
          placeholder="Выводы..."
        ></v-textarea>
      </div>
    </v-form>
  </step-wrapper>
</template>
<script>
import UnsavedMixin from "@/mixins/UnsavedMixin.js";
import stepWrapper from "../stepWrapper.vue";
import CreateAndEditMixin from "@/mixins/CreateAndEditMixin.js";

import { defaultSummaryReport } from "@/data/defaultObjects";
import SummaryReportService from "@/services/SummaryReportService";

export default {
  name: "consolidated-report-step6",
  components: {
    stepWrapper,
  },
  mixins: [CreateAndEditMixin, UnsavedMixin],
  inject: ["setDefaultData"],
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      apiService: SummaryReportService.step6,
      getDefaultObject: defaultSummaryReport.step6(),
      editItem: null,
      valid: false,
      rules: {
        required: (value) => (!!value && !!value.length) || "Укажите значение.",
      },

      loading: false,
      dataSource: {},
    };
  },
  computed: {},
  watch: {
    isUnsaved(val) {
      this.$emit("update:isUnsaved", val);
    },
    loading: {
      immediate: true,
      handler(val) {
        this.$emit("update:loading", val);
      },
    },
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      await this.baseInit();
      this.setDefaultData(this.dataSource);
    },
    actual() {
      this.init({
        query: {
          actual: true,
        },
      });
    },
  },
};
</script>
<style lang="scss">
.consolidated-report-step6 {
}
</style>
